import $kraken from '../../../../axios-API'
import fileArray from '../../../services/fileArray'

async function listFiles ({ commit }) {
  const response = await $kraken.get('/v2/manage/s/atualizacoes')

  const baseResponse = response.data.versions.map(a => ({
    name: a.Key,
    size: a.Size,
    path: a.Key,
    icon: '',
  }))
  const arrayFinal = fileArray.arrayMagic(baseResponse)
  fileArray.sizeSum(arrayFinal)
  fileArray.iconPlacer(arrayFinal)
  commit('SET_FILES', arrayFinal)
}

async function downloadFile ({ commit }, { file }) {
  const response = await $kraken.get(`/v2/manage/s/atualizacoes/download-url?key=${file}`)
  const url = response.data.download_url
  console.log(url)
  window.open(url)
}

async function deleteFile ({ commit }, { file }) {
  await $kraken.delete(`/v2/manage/s/atualizacoes/?key=${file}`)
  commit('DELETE_FILE', file)
}

async function getUploadUrl ({ commit }, { file }) {
  const response = await $kraken.get(`/v2/manage/s/atualizacoes/upload-url?key=${file}`)
  return response.data.upload_url
}

function updateDialog ({ commit }, value) {
  commit('SET_DIALOG', value)
}
function updateDirectories ({ commit }, value) {
  commit('SET_DIRECTORIES', value)
}

export default {
  listFiles,
  updateDialog,
  updateDirectories,
  downloadFile,
  deleteFile,
  getUploadUrl,
}
