
const fileList = state => { return state.files }
const getDialog = state => { return state.dialogState }
const getDirectories = state => { return state.directories }

export default {
  fileList,
  getDialog,
  getDirectories,
}
